<template>
  <div>
    <h2>Edit your photo</h2>
    <v-img class="mb-4" max-width="1350" max-height="240" :src="uploadedImage || currentImageUrl"></v-img>
    <FileUploader
      :files="photos"
      @fileChanged="imageFileChanged"      
    />
  </div>
</template>

<script>
import FileUploader from '@/components/common/FileUploader.vue';

export default {
  name: 'HeaderEditor',

  props: {
    currentImageUrl: String,
  },

  components: {
    FileUploader,
  },

  data: () => {
    return {
      photos: [],
    };
  },

  computed: {    
    uploadedImage() {
      return this.photos[0]?.url;
    },
    saveBtnEnabled() {
      return !!this.uploadedImage;
    },
  },

  methods: {
    imageFileChanged(imageObject) {      
      if (!imageObject) {
        return;
      }

      this.photos.push(imageObject);
      this.submitMethod();
    },

    save(close) {
      this.$store.commit('setHeaderPhoto', this.photos[0]);
      this.$store.commit('setCurrentChanged', true);
      close();
    },

    submitMethod() {
      if (this.saveBtnEnabled) {
        this.$emit('enterPress');
      }
    },
  },
};
</script>
