var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Edit your photo")]),
      _c("v-img", {
        staticClass: "mb-4",
        attrs: {
          "max-width": "1350",
          "max-height": "240",
          src: _vm.uploadedImage || _vm.currentImageUrl
        }
      }),
      _c("FileUploader", {
        attrs: { files: _vm.photos },
        on: { fileChanged: _vm.imageFileChanged }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }